import BasePage from '../../../../../../components/table_page';
import Form from './form';

export default {
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      isCalculateHeight: false,
    };
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('process_quota_list');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;

      if (code === 'add' || code === 'edit') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          row,
          paramsData: this.paramsProps,
        };
        this.openDrawer();
      }
    },
  },
};
