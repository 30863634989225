<template>
  <div class="copy-content">
    <div class="list">
      <div class="toolbar">
        <el-link icon="el-icon-plus" @click="addFn">添加</el-link>
      </div>
      <div class="form-list">
        <div class="form-item">
          <div class="label">抄送类型</div>
          <div class="val">
            <el-radio-group v-model="copyType" @change="radioChange">
              <el-radio :label="1">按角色</el-radio>
              <el-radio :label="0">按职位</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item" v-if="copyType === 1">
          <div class="label">
            <span class="danger">*</span>过滤条件
          </div>
          <div class="val">
            <el-select v-model="filterType" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="(item,k) in dictCodeList"
                :key="k"
                :label="item.dictValue"
                :value="item.dictCode"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <TablePage :listConfig="listConfig" ref="list" />
    </div>
    <div class="list">
      <div class="toolbar">
        <el-link icon="el-icon-delete" class="danger" @click="removeEvent">删除</el-link>
      </div>
      <vxe-table :data="tableData" show-overflow ref="subTable" :checkbox-config="{checkMethod: checkboxkMethod}">
        <vxe-table-column type="checkbox" width="36"></vxe-table-column>
        <vxe-table-column field="dateCode" :min-width="100" title="角色/职位编码"></vxe-table-column>
        <vxe-table-column field="dateName" :min-width="100" title="角色/职位名称"></vxe-table-column>
        <vxe-table-column field="dataType" :min-width="80" title="类型">
          <template #default="{ row }">
            <span>{{row.dataType === 1 ? '角色' : '职位'}}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="filterType" :min-width="100" title="过滤条件">
          <template #default="{ row }">
            <span>{{text(row.filterType)}}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="expandLister" :min-width="130" title="抄送扩展监听">
          <template #default="{ row }">
            <el-input v-if="row.dataType === 0" v-model="row.expandLister" placeholder="请输入内容"></el-input>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        icon="el-icon-check"
        @click="submit"
        :loading="$store.state.buttonApiIsLoading"
      >提交</el-button>
      <el-button type="danger" @click="close" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>

<script>
import TablePage from './table';
import request from '../../../../utils/request';

export default {
  name: 'orgManage',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      copyType: 1,
      filterType: '',
      dictCodeList: [],
      listConfig: {
        code: 'process_role_list',
        select: [],
        idKey: 'bpmRoleCode',
        params: {
          enableStatus: '009',
        },
      },
      tableData: [],
    };
  },
  components: {
    TablePage,
  },
  mounted() {
    this.getDictList();
    this.getList();
  },
  computed: {},
  methods: {
    getList() {
      request.get('/activiti/activitiCopyController/getListByProcessKey', { processKey: this.formConfig.row.key_ }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.result.map((v) => ({
            ...v,
            old: true,
          }));
        }
      });
    },
    checkboxkMethod({ row }) {
      return !row.old;
    },
    // 中文编译
    text(val) {
      let text = '';
      if (val) {
        const item = this.dictCodeList.find((v) => v.dictCode === val);

        text = item ? item.dictValue : '';
      }
      return text;
    },
    // 获取字典数据
    getDictList() {
      request
        .post('/mdm/mdmdictdata/list', { dictTypeCode: 'filter_type' })
        .then((res) => {
          this.dictCodeList = res.result;
        });
    },
    // 监听列表切换
    radioChange(e) {
      const idKey = e === 1 ? 'bpmRoleCode' : 'positionCode';
      const select = this.tableData
        .filter((v) => v.dataType === e)
        .map((v) => ({
          ...v,
          [`${idKey}`]: v.dateCode,
        }));

      let params = {};

      if (e === 1) {
        params = {
          enableStatus: '009',
        };
      }

      this.listConfig = {
        code: e === 1 ? 'process_role_list' : 'process_position_list',
        select,
        idKey,
      };
    },
    // 添加
    addFn() {
      const { copyType, filterType } = this;

      if (copyType === 1 && !filterType) {
        this.$message.error('请选择过滤条件');
      } else {
        const codes = this.tableData
          .filter((v) => v.dataType === copyType)
          .map((v) => v.dateCode);

        const selectRow = this.$refs.list.selectRow.filter(
          (v) => !codes.includes(v.bpmRoleCode || v.positionCode),
        );

        const list = selectRow.map((v) => ({
          dataType: copyType,
          dateCode: copyType === 1 ? v.bpmRoleCode : v.positionCode,
          dateName: copyType === 1 ? v.bpmRoleName : v.positionName,
          expandLister: '',
          filterType: copyType === 1 ? filterType : '0',
        }));

        this.tableData = this.tableData.concat(list);
      }
    },
    // 删除
    removeEvent() {
      const selectRecords = this.$refs.subTable.getCheckboxRecords();

      if (selectRecords.length === 0) {
        this.$message.error('请选择数据');
      } else {
        const codes = selectRecords.map((v) => v.dateCode);
        const oldSelectList = this.$refs.list.selectRow;

        const newList = oldSelectList.filter((v) => codes.includes(v.bpmRoleCode || v.positionCode));
        this.$refs.list.$refs.xTable.setCheckboxRow(newList, false);

        this.$refs.list.selectRow = oldSelectList.filter(
          (v) => !codes.includes(v.bpmRoleCode || v.positionCode),
        );
        this.$refs.list.selection = this.$refs.list.selection.filter(
          (v) => !codes.includes(v),
        );

        this.$refs.subTable.removeCheckboxRow();
        this.tableData = this.tableData.filter(
          (v) => !codes.includes(v.dateCode),
        );
      }
    },
    // 提交
    submit() {
      if (this.tableData.length === 0) {
        this.$message.warning('请选择抄送人员!');
      } else {
        const url = '/activiti/activitiCopyController/saveOrUpdate';
        const params = {
          items: this.tableData,
          processKey: this.formConfig.row.key_,
        };
        request.post(url, params).then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="less" scoped>
.copy-content {
  height: 100%;
  display: flex;

  .list {
    width: 50%;

    + .list {
      margin-left: 10px;
    }
  }

  .toolbar {
    margin-bottom: 10px;
  }

  .page {
    height: 80%;
  }
}

.form-list {
  padding: 15px 0 5px;

  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .label {
      flex-shrink: 0;
      margin-right: 10px;
    }

    .val {
      flex: 0.5;
      flex-shrink: 0;
    }
  }
}

.danger {
  color: #f56c6c !important;
}
</style>
