var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "copy-content" }, [
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "div",
          { staticClass: "toolbar" },
          [
            _c(
              "el-link",
              { attrs: { icon: "el-icon-plus" }, on: { click: _vm.addFn } },
              [_vm._v("添加")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "form-list" }, [
          _c("div", { staticClass: "form-item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("抄送类型")]),
            _c(
              "div",
              { staticClass: "val" },
              [
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.copyType,
                      callback: function ($$v) {
                        _vm.copyType = $$v
                      },
                      expression: "copyType",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("按角色")]),
                    _c("el-radio", { attrs: { label: 0 } }, [_vm._v("按职位")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.copyType === 1
            ? _c("div", { staticClass: "form-item" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "val" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.filterType,
                          callback: function ($$v) {
                            _vm.filterType = $$v
                          },
                          expression: "filterType",
                        },
                      },
                      _vm._l(_vm.dictCodeList, function (item, k) {
                        return _c("el-option", {
                          key: k,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _c("TablePage", { ref: "list", attrs: { listConfig: _vm.listConfig } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "div",
          { staticClass: "toolbar" },
          [
            _c(
              "el-link",
              {
                staticClass: "danger",
                attrs: { icon: "el-icon-delete" },
                on: { click: _vm.removeEvent },
              },
              [_vm._v("删除")]
            ),
          ],
          1
        ),
        _c(
          "vxe-table",
          {
            ref: "subTable",
            attrs: {
              data: _vm.tableData,
              "show-overflow": "",
              "checkbox-config": { checkMethod: _vm.checkboxkMethod },
            },
          },
          [
            _c("vxe-table-column", {
              attrs: { type: "checkbox", width: "36" },
            }),
            _c("vxe-table-column", {
              attrs: {
                field: "dateCode",
                "min-width": 100,
                title: "角色/职位编码",
              },
            }),
            _c("vxe-table-column", {
              attrs: {
                field: "dateName",
                "min-width": 100,
                title: "角色/职位名称",
              },
            }),
            _c("vxe-table-column", {
              attrs: { field: "dataType", "min-width": 80, title: "类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(_vm._s(row.dataType === 1 ? "角色" : "职位")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("vxe-table-column", {
              attrs: {
                field: "filterType",
                "min-width": 100,
                title: "过滤条件",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.text(row.filterType)))]),
                    ]
                  },
                },
              ]),
            }),
            _c("vxe-table-column", {
              attrs: {
                field: "expandLister",
                "min-width": 130,
                title: "抄送扩展监听",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      row.dataType === 0
                        ? _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: row.expandLister,
                              callback: function ($$v) {
                                _vm.$set(row, "expandLister", $$v)
                              },
                              expression: "row.expandLister",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              type: "primary",
              icon: "el-icon-check",
              loading: _vm.$store.state.buttonApiIsLoading,
            },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "danger", size: "small", icon: "el-icon-close" },
            on: { click: _vm.close },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _c("span", { staticClass: "danger" }, [_vm._v("*")]),
      _vm._v("过滤条件 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }