var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      model: {
        value: _vm.activeTab,
        callback: function ($$v) {
          _vm.activeTab = $$v
        },
        expression: "activeTab",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "流程节点", name: "process_node" } },
        [
          _vm.activeTab === "process_node"
            ? _c("ProcessNode", {
                attrs: { paramsProps: { processKey: _vm.formConfig.row.key_ } },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "业务关联", name: "process_business" } },
        [
          _vm.activeTab === "process_business"
            ? _c("ProcessBusiness", {
                attrs: { paramsProps: { processKey: _vm.formConfig.row.key_ } },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "流程指标", name: "process_index" } },
        [
          _vm.activeTab === "process_index"
            ? _c("Quota", {
                attrs: { paramsProps: { processKey: _vm.formConfig.row.key_ } },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }