<template>
  <div class="mutiple-select-item">
    <span class="mutiple-select-item-title">{{label}}</span>
    <span class="mutiple-select-item-icon" @click="removeClick">x</span>
  </div>
</template>

<script>
export default {
  name: 'mutiple-select-option',
  enName: '多选框的option',
  props: {
    label: String,
    value: [String, Number],
  },
  methods: {
    removeClick() {
      if (this.$parent) {
        this.$parent.childRemove(this.value);
      }
    },
  },
  created() {
    const parentNode = this.$parent;
    if (parentNode._name !== '<MutipleSelect>') {
      throw new Error('缺少父组件且父组件必须是mutiple-select');
    }
  },
};
</script>

<style lang="less" scoped>
.mutiple-select-item{
  padding: 0 4px 0 8px;
  border: 1px solid #ededed;
  height: 24px;
  line-height: 22px;
  margin-top: 2px;
  margin-right: 4px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  cursor: default;
  user-select: none;
  &-title{

  }
  &-icon{
    margin-left: 8px;
    font-size: 14px;
    color: rgba(0,0,0,.45);
    cursor: pointer;
    &-hover{
      color: #606266;
    }
  }
}
</style>
