import BasePage from '../../../../../components/table_page';

export default {
  extends: BasePage,
  props: {
    listConfig: Object,
  },
  data() {
    return {
      // pagerCount: 5,
    };
  },
  components: {},
  watch: {
    listConfig(val) {
      this.requestUrl = '';
      this.tableData = [];
      this.searchList = [];
      this.params = {};
      this.searchFormData = {};

      this.configs.tableConfig.idKey = val.idKey;

      this.getConfigList(val.code).then((res) => {
        this.selectRow = JSON.parse(JSON.stringify(val.select));
      });
    },
  },
  created() {
    this.configs.tableConfig.idKey = this.listConfig.idKey;
    this.params = this.listConfig.params;
    this.getConfigList(this.listConfig.code);
  },
  methods: {
    setColumn(col) {
      const _col = { ...col };
      _col.span = 8;
      return _col;
    },
  },
};
