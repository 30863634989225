import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    formConfig: Object,
  },
  data() {
    return {
      formFunctionCode: 'process_quota_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'visible') {
        v.props = {
          'active-value': 'Y',
          'inactive-value': 'N',
        };

        v.value = 'N';
      } else if (v.field === 'processNodeName') {
        v.restful = '/activiti/taNodeConfigController/queryByProcessKey';
        v.restfulParams = { processKey: this.formConfig.paramsData.processKey };
        // v.refresh = true;
        v.props = {
          ...v.props,
          remoteParams: 'processNodeName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'processNodeName',
          value: 'id',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/activiti/taacttarget/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              if (!data.ext1 || data.ext1 === '') {
                data.processNodeName = [];
              } else {
                data.processNodeName = data.ext1.split(',');
              }
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      console.log(formData);
      if (formData.processNodeName && formData.processNodeName.length > 0) {
        formData.ext1 = formData.processNodeName.join();
      } else {
        formData.ext1 = '';
      }
      if (formData) {
        let url = '/activiti/taacttarget/save';
        const params = { ...formData, ...this.formConfig.paramsData };

        if (this.formConfig.code === 'edit') {
          url = '/activiti/taacttarget/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
