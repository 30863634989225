import request from '../../../../../utils/request';
import Form, { formCreate } from '../../../../../components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'process_manage_add_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'key') {
        v.validate = [
          ...v.validate,
          {
            trigger: 'blur',
            pattern: /^(?!\d*$)/,
            message: '流程key必须为字母或字母+数字',
          },
        ];
      }
      return v;
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/activiti/designController/newModel';
        const params = { ...formData };

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('submit', res.message);
          }
        });
      }
    },
  },
};
