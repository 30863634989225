<template>
  <div class="home">
    <Table ref="basePage" @callBack='callBack'/>
    <a-drawer
      :visible="visible"
      width="100%"
      :destroyOnClose="true"
      :footer="null"
      :maskClosable="false"
      @close="close"
      :bodyStyle="{padding: '0',height: '100vh'}"
    >
      <iframe
        :src="src"
        style="width: 100%;height: 100%;"
        scrolling="no"
        frameborder="no"
        ref="iframe"
      ></iframe>
    </a-drawer>
  </div>
</template>

<script>
import Table from './table';

export default {
  name: 'processMapping',
  data() {
    return {
      visible: false,
      src: null,
    };
  },
  components: {
    Table,
  },
  methods: {
    // 绘制弹框
    callBack(id) {
      this.visible = true;
      this.src = `/activiti/designController/editor?modelId=${id}`;
    },
    // 关闭
    close() {
      this.visible = false;
      this.src = null;
    },
    closeModal(val) {
      if (val.data.close) {
        // 刷新列表
        this.$refs.basePage.getList();
        // 关闭弹框
        this.close();
      }
    },
  },
  created() {
    window.addEventListener('message', this.closeModal, false);
  },
  destroyed() {
    window.removeEventListener('message', this.closeModal);
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-drawer-close {
  width: 47px;
  height: 47px;
  line-height: 47px;
}
</style>
