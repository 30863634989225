import BasePage from '../../../../../../components/table_page';
import Form from './form';
import request from '../../../../../../utils/request';

export default {
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      isCalculateHeight: false,
    };
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('process_business_context_list');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;

      if (code === 'add' && this.tableData.length > 0) {
        return this.$message.warning('请删除后再新增数据！');
      }

      if (code === 'add' || code === 'edit') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          paramsData: this.paramsProps,
          row,
        };
        this.openModal();
      }
    },
    // 设置列
    setColumn(col) {
      const _col = col;
      if (_col.field === 'pageRelation') {
        _col.formatter = ({ row }) => row.menuList.map((v) => v.bizName).join(',');
      } else if (_col.field === 'orgRelation') {
        _col.formatter = ({ row }) => row.orgList.map((v) => v.bizName).join(',');
      }

      return _col;
    },
    // 删除
    deleteClick({ val }) {
      if (this.selectRow.length === 0) return this.$message.error('请选择一条数据！');

      if (val.code !== 'delete') return;
      const requestParam = this.paramsProps.processKey;
      request
        .post('/activiti/taProcessBizRelationController/deleteRelation', requestParam)
        .then((res) => {
          if (res.success) {
            this.$message.success('删除成功');
            this.getList();
          }
        });
    },
  },
};
