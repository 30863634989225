<template>
  <div>
    <div class="tool-box">
      <el-button @click="btnAdd">添加</el-button>
      <el-button type="danger" plain @click="btnRemoveAll">清空</el-button>
    </div>
    <MutipleSelect @remove="selectRemove">
      <MutipleSelectOption
        v-for="(item,index) in menuList"
        :key="index"
        :label="item.functionName"
        :value="item.functionCode"
      ></MutipleSelectOption>
    </MutipleSelect>
    <ModalPage ref="ModalPage" @onGetSelect="dialog_btnConfirm"></ModalPage>
  </div>
</template>

<script>
import {
  MutipleSelect,
  MutipleSelectOption,
} from '../../../../../../../components/mutiple-select/index';
import * as utils from '../../../../../../../utils/index';
import ModalPage from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  enName: '可选组织',
  components: {
    MutipleSelect,
    MutipleSelectOption,
    ModalPage,
  },
  props: {
    value: Array,
  },
  watch: {
    value: {
      handler(val) {
        if (val && Array.isArray(val)) this.menuList = val;
      },
    },
  },
  data() {
    return {
      menuList: [],
    };
  },
  methods: {
    /** @desc 按钮新增 */
    btnAdd() {
      this.$refs.ModalPage.openSelectModal({
        functionCode: 'process_business_page_list',
        data: this.menuList,
        idKey: 'functionCode',
      });
    },
    /** @desc 按钮清空 */
    btnRemoveAll() {
      this.menuList = [];
      this.callbackEmit();
    },
    /** @desc 下拉框事件 */
    selectRemove(value) {
      this.menuList = this.menuList.filter((v) => v.functionCode !== value);
      this.callbackEmit();
    },
    /** @desc 弹窗确认事件 */
    dialog_btnConfirm(params) {
      const mergeArr = utils.duplicate(
        [...this.menuList, ...params],
        'functionCode',
      );
      this.menuList = mergeArr;
      this.callbackEmit();
    },
    callbackEmit() {
      this.$emit('input', this.menuList);
    },
  },
};
</script>
<style lang="less" scoped>
.tool-box {
  padding-bottom: 10px;
}
</style>
