var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("Table", { ref: "basePage", on: { callBack: _vm.callBack } }),
      _c(
        "a-drawer",
        {
          attrs: {
            visible: _vm.visible,
            width: "100%",
            destroyOnClose: true,
            footer: null,
            maskClosable: false,
            bodyStyle: { padding: "0", height: "100vh" },
          },
          on: { close: _vm.close },
        },
        [
          _c("iframe", {
            ref: "iframe",
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: _vm.src, scrolling: "no", frameborder: "no" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }