import BasePage from '../../../../../../components/table_page';
import Form from './form';

export default {
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      configs: {
        formConfig: {
          item: [
            {
              field: 'processVersionKey',
              search: 'true',
              title: '流程版本号',
              type: 'select',
              apiUrl: '/activiti/taNodeConfigController/getAllProcessVersionKey',
              optionsKey: {
                label: 'processVersionKey',
                value: 'processVersionKey',
              },
              paramsFixed: this.paramsProps,
            },
          ],
        },
        toolBarConfig: [
          {
            name: '编辑',
            buttonType: '0',
            buttonCode: 'edit',
            code: 'edit',
          },
        ],
      },
      isCalculateHeight: false,
    };
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('processr_node');
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    modalClick({ val, row }) {
      const { code, name } = val;

      if (code === 'edit') {
        if (row.length === 0) {
          this.$message('请选择');
          return;
        }
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          row: row[0],
        };
        this.openFull();
      }
    },
  },
};
