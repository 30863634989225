<template>
  <div>
    <div class="tool-box">
      <el-button @click="btnAdd">添加</el-button>
      <el-button type="danger" plain @click="btnRemoveAll">清空</el-button>
      <el-checkbox
        style="margin-left: 10px"
        v-model="isCurrentOrg"
        true-label="Y"
        false-label="N"
      >是否仅当前组织</el-checkbox>
    </div>
    <MutipleSelect @remove="selectRemove">
      <MutipleSelectOption
        v-for="(item,index) in orgList"
        :key="index"
        :label="item.orgName"
        :value="item.orgCode"
      ></MutipleSelectOption>
    </MutipleSelect>

    <ModalOrg ref="ModalOrg" @onGetSelect="dialog_btnConfirm"></ModalOrg>
  </div>
</template>

<script>
import {
  MutipleSelect,
  MutipleSelectOption,
} from '../../../../../../../components/mutiple-select/index';
import ModalOrg from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  enName: '可选组织',
  components: {
    MutipleSelect,
    MutipleSelectOption,
    ModalOrg,
  },
  props: {
    value: Array,
    isOnlyCurrentOrg: String,
  },
  inject: ['$MODALFORM'],
  watch: {
    value: {
      handler(val) {
        if (val && Array.isArray(val)) this.orgList = val;
      },
    },
    isCurrentOrg(v) {
      this.$MODALFORM.fApi.setValue('isOnlyCurrentOrg', v);
    },
    isOnlyCurrentOrg: {
      immediate: true,
      handler(v) {
        if (v) this.isCurrentOrg = v;
      },
    },
  },
  data() {
    return {
      orgList: [],
      isCurrentOrg: '',
    };
  },
  methods: {
    /** @desc 按钮新增 */
    btnAdd() {
      this.$refs.ModalOrg.openSelectModal({
        functionCode: 'process_business_org_list',
        data: this.orgList,
        selectionList: this.orgList,
        // noReset: true,
        idKey: 'orgCode',
      });
    },
    /** @desc 按钮清空 */
    btnRemoveAll() {
      this.orgList = [];
      this.callbackEmit();
    },
    /** @desc 下拉框事件 */
    selectRemove(value) {
      this.orgList = this.orgList.filter((v) => v.orgCode !== value);
      this.callbackEmit();
    },
    /** @desc 弹窗确认事件 */
    dialog_btnConfirm(params) {
      this.orgList = params;
      this.callbackEmit();
    },
    callbackEmit() {
      this.$emit('input', this.orgList);
    },
  },
};
</script>

<style lang="less" scoped>
.tool-box {
  padding-bottom: 10px;
}
</style>
