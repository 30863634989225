<template>
  <el-tabs v-model="activeTab">
    <el-tab-pane label="流程节点" name="process_node">
      <ProcessNode v-if="activeTab === 'process_node'" :paramsProps="{processKey: formConfig.row.key_}"></ProcessNode>
    </el-tab-pane>
    <el-tab-pane label="业务关联" name="process_business">
      <ProcessBusiness v-if="activeTab === 'process_business'" :paramsProps="{processKey: formConfig.row.key_}"></ProcessBusiness>
    </el-tab-pane>
    <el-tab-pane label="流程指标" name="process_index">
      <Quota v-if="activeTab === 'process_index'" :paramsProps="{processKey: formConfig.row.key_}"></Quota>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import ProcessNode from '../components/node_config';
import ProcessBusiness from '../components/business_context';
import Quota from '../components/quota';

export default {
  data() {
    return {
      activeTab: 'process_node',
    };
  },
  props: {
    formConfig: Object,
  },
  components: {
    ProcessNode,
    ProcessBusiness,
    Quota,
  },
};
</script>
