import BasePage from '../../../../../components/table_page';
import Form from '../form';
import Configure from '../form/configure.vue';
import CopyManage from '../../../components/copy_manage';
import EditionManage from '../components/edition/edition.vue';
import request from '../../../../../utils/request';

export default {
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    Configure,
    CopyManage,
    EditionManage,
  },
  created() {
    this.getConfigList('process_manage_list');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'add') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
        };
        this.modalConfig.width = '500px';
        this.modalConfig.height = '300px';
        this.openModal();
      } else if (code === 'edit') {
        this.$emit('callBack', row.id);
      } else if (code === 'deploy') {
        if (row.length === 0) {
          this.$message('请选择未发布的流程进行发布');
          return;
        }

        request.get('/activiti/operateActivitiController/deployment', { id: row[0].id }).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      } else if (code === 'config') {
        this.modalConfig.title = '节点配置';
        this.formName = 'Configure';
        this.formConfig = {
          code,
          row,
        };
        this.openFull();
      } else if (code === 'copy') {
        this.modalConfig.title = '抄送配置';
        this.formName = 'CopyManage';
        this.formConfig = {
          code,
          row,
        };
        this.openFull();
      } else if (code === 'edition') {
        this.modalConfig.title = '历史版本';
        this.formName = 'EditionManage';
        this.formConfig = {
          code,
          row,
        };
        this.openFull();
      }
    },
    // 绘制弹框
    onSubmit(id) {
      this.$emit('callBack', id);
    },
  },
};
