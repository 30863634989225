import BasePage from '../../../../../../components/table_page';
import Form from './form/form.vue';

export default {
  props: {
    paramsProps: Object,
  },
  extends: BasePage,
  data() {
    return {};
  },
  created() {
    this.getConfigList('process_edition_list');
  },
  components: {
    Form,
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;

      if (code === 'view_prcoess_img') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          row,
        };
        this.openDrawer();
      } else if (code === 'getDetail') {
        window.open(`/activiti/designController/showBpmn?deploymentId=${row.deploymentId}`, '_blank');
      }
    },
  },
};
