var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mutiple-select-item" }, [
    _c("span", { staticClass: "mutiple-select-item-title" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "span",
      {
        staticClass: "mutiple-select-item-icon",
        on: { click: _vm.removeClick },
      },
      [_vm._v("x")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }