var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tool-box" },
        [
          _c("el-button", { on: { click: _vm.btnAdd } }, [_vm._v("添加")]),
          _c(
            "el-button",
            {
              attrs: { type: "danger", plain: "" },
              on: { click: _vm.btnRemoveAll },
            },
            [_vm._v("清空")]
          ),
          _c(
            "el-checkbox",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { "true-label": "Y", "false-label": "N" },
              model: {
                value: _vm.isCurrentOrg,
                callback: function ($$v) {
                  _vm.isCurrentOrg = $$v
                },
                expression: "isCurrentOrg",
              },
            },
            [_vm._v("是否仅当前组织")]
          ),
        ],
        1
      ),
      _c(
        "MutipleSelect",
        { on: { remove: _vm.selectRemove } },
        _vm._l(_vm.orgList, function (item, index) {
          return _c("MutipleSelectOption", {
            key: index,
            attrs: { label: item.orgName, value: item.orgCode },
          })
        }),
        1
      ),
      _c("ModalOrg", {
        ref: "ModalOrg",
        on: { onGetSelect: _vm.dialog_btnConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }