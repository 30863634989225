import request from '../../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../../components/form';

import OptionalPage from './optional_page.vue';
import OptionalOrg from './optional_org.vue';

/** @desc 可选页面 */
formCreate.component('OptionalPage', OptionalPage);
/** @desc 可选组织 */
formCreate.component('OptionalOrg', OptionalOrg);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'process_business_context_form',
    };
  },
  methods: {
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.setValue({
          menuList: this.formConfig.row.menuList.map((v) => ({ ...v, functionCode: v.bizCode, functionName: v.bizName })),
          orgList: this.formConfig.row.orgList.map((v) => ({ ...v, orgCode: v.bizCode, orgName: v.bizName })),
          formParam: this.formConfig.row.formParam,
          isOnlyCurrentOrg: this.formConfig.row.isOnlyCurrentOrg,
        });
        this.updateRules({
          orgList: {
            props: {
              isOnlyCurrentOrg: this.formConfig.row.isOnlyCurrentOrg,
            },
          },
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值

      if (formData) {
        const url = '/activiti/taProcessBizRelationController/insertRelation';
        const params = { ...formData, ...this.formConfig.paramsData };

        if (params.menuList) {
          params.menuList = params.menuList.map((v) => ({ ...v, bizCode: v.functionCode, bizName: v.functionName }));
        }

        if (params.orgList) {
          params.orgList = params.orgList.map((v) => ({ ...v, bizCode: v.orgCode, bizName: v.orgName }));
        }

        // params.processKey = this.formConfig.row.processKey;
        params.isOnlyCurrentOrg = params.isOnlyCurrentOrg || 'N';

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功!');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
