var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-box" }, [
    _vm.id
      ? _c("div", [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: "/activiti/designController/showPic?deploymentId=" + _vm.id,
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }