<template>
  <div class="mutiple-select">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'mutiple-select',
  enName: '多选框',
  methods: {
    childRemove(value) {
      console.log('select打印========', value);
      this.$emit('remove', value);
    },
  },
};
</script>

<style lang="less" scoped>
.mutiple-select{
height: auto;
width: 100%;
display: inline-block;
border: 1px solid #ededed;
border-radius: 4px;
padding: 2px 4px;
min-height: 120px;
display: flex;
flex-wrap: wrap;
box-sizing: border-box;
}
</style>
