<template>
  <div class="content-box">
    <div v-if="id">
      <img class="img" :src="`/activiti/designController/showPic?deploymentId=${id}`" />
    </div>
  </div>
</template>

<script>
// import request from '../../../../../../../utils/request';

export default {
  props: {
    formConfig: Object,
  },
  data() {
    return {
      id: '',
    };
  },
  created() {
    this.id = this.formConfig.row.deploymentId;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;

  .img {
    max-width: 100%;
  }
}
</style>
