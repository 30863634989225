import Form from '../../../../../../../components/form';
import request from '../../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'process_node_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'btnAuthRoleId') {
        v.restful = '/activiti/taFuncRoleController/getAllBtnRole';
        // v.restfulParams = { enableStatus: '009', actType: 'stable_charge' };
        v.optionsKey = { label: 'roleName', value: 'id' };
        // v.restfulParamsGetValue = { controlIds: 'controlIds' };
        // v.refresh = true;
      } else if (v.field === 'approveType') {
        // 0=普通1=最终审批2=审核3=复核4=财务审批
        v.options = [
          {
            value: 0,
            label: '普通',
          },
          {
            value: 1,
            label: '最终审批',
          },
          {
            value: 2,
            label: '审核',
          },
          {
            value: 3,
            label: '复核',
          },
          {
            value: 4,
            label: '财务审批',
          },
        ];
      } else if (v.field === 'formRole') {
        v.options = [
          {
            value: 'view1',
            label: 'view1',
          },
          {
            value: 'view2',
            label: 'view2',
          },
          {
            value: 'view3',
            label: 'view3',
          },
          {
            value: 'update1',
            label: 'update1',
          },
          {
            value: 'update2',
            label: 'update2',
          },
          {
            value: 'update3',
            label: 'update3',
          },
        ];
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const btnAuthRoleId = this.getRule('btnAuthRoleId');
      btnAuthRoleId.on.getLabel = (e) => {
        this.setValue({
          btnAuthRoleName: e,
        });
      };

      if (this.formConfig.code === 'edit') {
        const keysList = [
          'canBack',
          'canDistribution',
          'canPoint',
          'auditRepeatSkip',
          'auditNullSkip',
          'cancelFlag',
          'configInside',
          'manualNext',
          'isDefaultChoose',
          'userIsView',
          'viewRelation',
          'isAddFlow',
        ];
        request
          .get('/activiti/taNodeConfigController/query', { id: this.formConfig.row.id })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              Object.keys(data).forEach((key) => {
                if (keysList.includes(key)) {
                  data[key] = data[key] || '0';
                }
              });
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/activiti/taNodeConfigController/update';
        const params = Object.assign(this.formConfig.row, formData);

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
